function getEnvVarOrThrow(
  environmentVariable: string | undefined,
  name: string,
) {
  if (environmentVariable === undefined) {
    throw new Error(`Environment variable ${name} was undefined`);
  }
  return environmentVariable;
}

export const IS_PRODUCTION = import.meta.env.PROD;
export const AUTH0_DOMAIN = getEnvVarOrThrow(
  import.meta.env.VITE_AUTH0_DOMAIN,
  "VITE_AUTH0_DOMAIN",
);
export const AUTH0_CLIENT_ID = getEnvVarOrThrow(
  import.meta.env.VITE_AUTH0_CLIENT_ID,
  "VITE_AUTH0_CLIENT_ID",
);
export const GITHUB_CLIENT_ID = getEnvVarOrThrow(
  import.meta.env.VITE_GITHUB_CLIENT_ID,
  "VITE_GITHUB_CLIENT_ID",
);
export const TEST_API_URL =
  import.meta.env.VITE_TEST_API_URL ??
  "https://test-console-api.zuploservices.com";
export const LE_API_URL = import.meta.env.VITE_LE_API_URL;
export const AUTH0_SCOPE = "openid email";
export const AUTH0_AUDIENCE = "https://api.zuplo.com/";
export const LAUNCH_DARKLY_CLIENT_ID =
  import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID ?? "61b9f9970e42590f6e801af6";
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? undefined;
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV ?? "local";
export const SENTRY_RELEASE = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;
export const GIT_COMMIT_SHA = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;
export const MANAGEMENT_API_URL = import.meta.env.VITE_MANAGEMENT_API_URL;
export const MANAGEMENT_API_VERSION =
  import.meta.env.VITE_MANAGEMENT_API_VERSION ?? "v1";
export const CDN_URL = getEnvVarOrThrow(
  import.meta.env.VITE_CDN_URL,
  "VITE_CDN_URL",
);
export const CUSTOMER_ASSETS_URL = import.meta.env.VITE_CUSTOMER_ASSETS_URL;
export const PUSHER_APP_KEY =
  import.meta.env.VITE_PUSHER_APP_KEY ?? "80ba5b5299ddabd208c6";
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER ?? "mt1";
export const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY;
export const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST;

export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
export const STRIPE_PRICING_TABLE_ID = import.meta.env
  .VITE_STRIPE_PRICING_TABLE_ID;
export const KNOCK_PUBLIC_API_KEY =
  import.meta.env.VITE_KNOCK_PUBLIC_API_KEY ??
  "pk_wK3Thr51luKYzZ6OFi3cIjeR8sqII48OHumKN0Frzi4";
export const KNOCK_FEED_CHANNEL_ID =
  import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID ??
  "ca81cc0f-9334-4c96-a32a-06cf3d873d7b";
export const REDDIT_PIXEL_ID = import.meta.env.VITE_REDDIT_PIXEL_ID;
export const GOOGLE_TAG_MANAGER_ID = import.meta.env.VITE_GOOGLE_PRODUCT_ID;
